import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { graphql, Link, navigate } from "gatsby";
import { use100vh } from "react-div-100vh";
import { useForm } from "react-hook-form";
import { PrismicRichText } from "@prismicio/react";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-context";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { encodeForm } from "../components/utils/encode-form";

// Icons
import { CloseIcon } from "../components/icons/slideshow-icons";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  min-height: ${props => props.height}px;
  color: #0b646f;
  padding: 0 20px;

  & .top-navigation {
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    padding: 0 25px;

    & h1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      font-size: 18px;
      line-height: 50px;
      letter-spacing: 0;

      color: #0b646f;

      & .small {
        font-size: 10px;
        padding: 0 10px;
        letter-spacing: 0.02em;
      }
    }

    @media (max-width: 960px) {
      grid-template-columns: 20px 1fr 20px;
      padding: 0;

      & h1 {
        font-size: 14px;
        line-height: 30px;

        & .small {
          font-size: 6px;
          padding: 0 6px;
        }
      }
    }
  }
`;

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #f5eadf;

  z-index: 100;
  height: 100%;
  width: 100%;

  min-height: calc(${props => props.height}px - 51px - 51px);

  & .lightbox-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const SignupFormContainer = styled.div`
  max-width: 415px;
  width: 100%;
  padding: 40px 0;

  & .text-container {
    margin: 0 0 100px 0;
  }

  & form {
    margin: 0 auto;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      margin: 0 0 20px 0;

      & input,
      & textarea {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #0b646f;
        background: transparent;
        border-bottom: 1px solid #debb9b;

        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.04em;

        font-family: "NewsGothicMTProBold", "Helvetica Neue", "Lucida Grande",
          sans-serif;

        ::-webkit-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        ::-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-ms-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
        :-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #0b646f;

          font-family: "HouseofVoltaireWeb-Display", "Helvetica Neue",
            "Lucida Grande", sans-serif;
        }
      }
    }

    & textarea {
      margin: 5px 0 0 0;
    }

    & .privacy-container {
      display: grid;
      grid-template-columns: 20px auto;
      grid-column-gap: 20px;
      /* align-items: baseline; */
      line-height: 16px;
      margin: 30px 0 0 0;

      color: #0b646f;

      & p {
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.02em;

        margin: 0;

        & a {
          color: #c59a72;
        }

        @media (max-width: 1440px) {
          font-size: 14px;
          line-height: 20px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & .gdpr-checkbox {
        width: 13px;
        height: 13px;

        position: relative;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label {
        width: 13px;
        height: 13px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #debb9b;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox label:after {
        content: "";
        width: 7px;
        height: 3px;
        position: absolute;
        top: 4px;
        left: 3px;
        border: 1px solid #debb9b;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);

        @media (max-width: 500px) {
          width: 6px;
          height: 3px;
          top: 3px;
          left: 2px;
        }
      }

      & .gdpr-checkbox label:hover::after {
        opacity: 1;
      }

      & .gdpr-checkbox input[type="checkbox"] {
        width: 13px;
        height: 13px;

        visibility: hidden;

        @media (max-width: 500px) {
          width: 11px;
          height: 11px;
        }
      }

      & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
    }

    & .errors {
      margin: 10px 0 0 0;
      grid-column: span 2;

      & p {
        font-size: 13px;
        line-height: 17px;

        color: rgba(0, 0, 0, 0.4);

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }

    & .submit-button {
      display: block;

      font-size: 15px;
      line-height: 39px;
      letter-spacing: 0.02em;

      color: #0b646f;
      background-color: #fff;

      width: 100%;
      height: 40px;
      margin: 50px 0 0 0;

      transition: 250ms background-color ease, 250ms color ease;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #fff;
          background-color: #0b646f;
        }
      }

      @media (max-width: 960px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 500px) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }

  & .thank-you-message {
    margin: 100px 0 0 0;
    text-align: center;

    & p {
      color: #0b646f;
      margin: 0;
    }
  }
`;

const CloseLightbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 960px) {
    padding: 8px 10px;
    height: 29px;

    & svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const Contact = ({ data, location }) => {
  const height = use100vh();
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [backButtonAsLink, setBackButtonAsLink] = useState(true);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#f5eadf`,
      pageType: `contact`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  useEffect(() => {
    if (location !== undefined) {
      if (location.state !== undefined && location.state !== null) {
        if (location.state.from !== undefined && location.state.from !== null) {
          if (location.state.from === `website`) {
            setBackButtonAsLink(false);
          } else {
            setBackButtonAsLink(true);
          }
        }
      }
    }
  }, [location]);

  // Form Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [formMessage, setFormMessage] = useState(``);
  const [checked, setChecked] = useState(false);

  const onSubmit = (formData, event) => {
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeForm({
        "form-name": `contact-form`,
        subject: `House of Voltaire - Contact Form Submission`,
        ...formData,
      }),
    })
      .then(() => {
        setFormMessage(data.prismicContact.data.thank_you_text.text);
        reset();
      })
      .catch(error => {
        console.log("error:", error);
        setFormMessage(
          "An error occurred while submitting your enquiry. Please email us directly at: info@studiovoltaire.org"
        );
      });

    event.preventDefault();
  };

  useEffect(() => {
    if (formMessage !== ``) {
      const closeForm = setTimeout(() => {
        setFormMessage(``);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <>
      <PageSeo
        seoTitle={data.prismicContact.data.seo_title}
        seoText={data.prismicContact.data.seo_description}
        seoImage={data.prismicContact.data.seo_image.url}
      />

      <Page height={height}>
        <div className="top-navigation">
          <div className="spacer" />

          <Link to="/">
            <h1 className="logo uppercase">
              House
              <span className="small">of</span>
              Voltaire
            </h1>
          </Link>

          <CloseLightbox>
            {backButtonAsLink ? (
              <Link to={`/`}>
                <CloseIcon />
              </Link>
            ) : (
              <button onClick={() => navigate(-1)}>
                <CloseIcon />
              </button>
            )}
          </CloseLightbox>
        </div>

        <LightboxContainer height={height}>
          <div className="lightbox-wrapper">
            <SignupFormContainer>
              <div className="text-container center-text">
                <PrismicRichText
                  field={data.prismicContact.data.text.richText}
                />
              </div>

              {formMessage === `` ? (
                <form
                  name="contact-form"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input type="hidden" name="form-name" value="contact-form" />
                  <input type="hidden" name="bot-field" />
                  <input
                    type="hidden"
                    name="subject"
                    value="House of Voltaire - Contact Form Submission"
                  />

                  <div className="field">
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      autoComplete="given-name"
                      aria-required="true"
                      aria-label="First Name"
                      placeholder={`First name`}
                      ref={register({
                        required: true,
                      })}
                    />
                  </div>
                  <div className="field">
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      autoComplete="family-name"
                      aria-required="true"
                      aria-label="Last Name"
                      placeholder={`Last name`}
                      ref={register({
                        required: true,
                      })}
                    />
                  </div>
                  <div className="field">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      aria-required="true"
                      aria-label="Email"
                      placeholder={`Email`}
                      ref={register({
                        required: true,
                      })}
                    />
                  </div>
                  <div className="field">
                    <textarea
                      id="text-area"
                      name="message"
                      type="text"
                      aria-label="Message"
                      placeholder={`Message`}
                      ref={register()}
                      rows="4"
                    />
                  </div>
                  <div className="privacy-container">
                    <div
                      className="gdpr-checkbox"
                      onClick={() => setChecked(!checked)}
                    >
                      <input
                        type="checkbox"
                        id="gdpr-checkbox"
                        name="privacyPolicyAgreed"
                        value={checked}
                        checked={checked}
                        onChange={e => {
                          setChecked(e.target.checked);
                        }}
                        ref={register({
                          required: true,
                        })}
                      />
                      <label htmlFor="privacyPolicyAgreed"></label>
                    </div>

                    <p className="news-gothic">
                      You agree to our{" "}
                      <Link to={`/privacy-policy/`}>privacy policy</Link>
                    </p>
                  </div>

                  {errors.privacyPolicyAgreed && (
                    <div className="errors">
                      <p className="news-gothic">
                        Please tick this box to proceed
                      </p>
                    </div>
                  )}

                  <button type="submit" className="submit-button">
                    Submit
                  </button>
                </form>
              ) : (
                <div className="thank-you-message">
                  <p>{formMessage}</p>
                </div>
              )}
            </SignupFormContainer>
          </div>
        </LightboxContainer>
      </Page>
    </>
  );
};

export default withPrismicPreview(Contact);

export const query = graphql`
  {
    prismicContact {
      data {
        title {
          text
          html
        }
        text {
          richText
        }
        thank_you_text {
          text
        }
        seo_title
        seo_image {
          url
        }
        seo_description
      }
    }
  }
`;
